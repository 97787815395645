<template>
  <form-field :name="field.name">
    <v-text-field
      v-model="field.value"
      v-bind="field.props"
      label="Enter email address"
      box
    />
  </form-field>
</template>

<script>
import formField from '@/mixins/form-field'

export default {
  mixins: [formField],
}
</script>
